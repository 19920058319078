/*
 * @Author: 王枫叶
 * @Date: 2020-08-10 22:55:30
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-10-28 22:55:14
 */
import request from "@/utils/request";

export default data =>
  request({
    method: "post",
    url: "/qaz/wsx/edc/hirs5j1h",
    data
  });
