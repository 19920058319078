<template>
  <div class="wrapper" id="wrapper">
    <div class="content">
      <!--          <img class="bg" src="@/assets/images/mobile1/bg.webp" />-->

      <div class="topBar">
        <img class="logo" src="@/assets/images/mobile1/logo.png" />
        <img
          @click="iosDownload"
          v-if="os.isPhone"
          class="tipRight"
          src="@/assets/images/mobile1/tipRight.png"
        />
        <img
          @click="androidDownload"
          v-else
          class="tipRight"
          src="@/assets/images/mobile1/tipRight.png"
        />
      </div>

      <!--          <div class="tip" v-show="swiperIndex == 0" :class="swiperIndex == 0?'animate__animated animate__fadeInDown':''">-->
      <!--              <img src="@/assets/images/mobile1/tip_1.png">-->
      <!--          </div>-->

      <!--          <div class="tip" v-show="swiperIndex == 1" :class="swiperIndex == 1?'animate__animated animate__fadeInDown':''">-->
      <!--              <img src="@/assets/images/mobile1/tip_2.png">-->
      <!--          </div>-->

      <!--          <div class="tip" v-show="swiperIndex == 2" :class="swiperIndex == 2?'animate__animated animate__fadeInDown':''">-->
      <!--              <img src="@/assets/images/mobile1/tip_3.png">-->
      <!--          </div>-->

      <van-swipe
        class="swipe"
        ref="swipe"
        duration="1000"
        autoplay="3000"
        :show-indicators="false"
        vertical
        loop
        @change="swipeChange"
      >
        <van-swipe-item>
          <img class="bg" src="@/assets/images/mobile1/bg.webp" />
          <div class="contentSwipe contentSwipe1">
            <div class="tip">
              <img src="@/assets/images/mobile1/tip_1.png" />
            </div>
            <div class="people">
              <img src="@/assets/images/mobile1/people_1.webp" />
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <img class="bg" src="@/assets/images/mobile1/bg.webp" />
          <div class="contentSwipe contentSwipe2">
            <div class="tip">
              <img src="@/assets/images/mobile1/tip_2.png" />
            </div>
            <div class="people">
              <img src="@/assets/images/mobile1/people_2.webp" />
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <img class="bg" src="@/assets/images/mobile1/bg.webp" />
          <div class="contentSwipe contentSwipe3">
            <div class="tip">
              <img src="@/assets/images/mobile1/tip_3.png" />
            </div>
            <div class="people">
              <img src="@/assets/images/mobile1/people_3.webp" />
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>

      <div class="downloadBox">
        <!-- <img @click="toApplication" class="moreApps" src="@/assets/images/mobile1/moreApps.png"> -->
        <img
          @click="iosDownload"
          v-if="os.isPhone"
          class="downloadBtn"
          src="@/assets/images/mobile1/downloadBtn.png"
        />
        <img
          @click="androidDownload"
          v-else
          class="downloadBtn"
          src="@/assets/images/mobile1/downloadBtn.png"
        />
      </div>
      <div
        class="tutorialTip"
        @click="os.isPhone ? iosDownload() : androidDownload()"
      ></div>
    </div>

    <!--    <van-swipe class="swiper" autoplay="3000" @change="swipeChange">-->
    <!--      <van-swipe-item>-->
    <!--        <div class="content">-->
    <!--          <div class="tip">-->
    <!--            <img class="logo" src="@/assets/images/mobile1/logo.png" />-->
    <!--            <img-->
    <!--              class="tipRight"-->
    <!--              @click="joinGroup"-->
    <!--              src="@/assets/images/mobile1/tipRight.png"-->
    <!--            />-->
    <!--          </div>-->
    <!--          <div class="main">-->
    <!--            <img-->
    <!--              class="left"-->
    <!--              :class="-->
    <!--                swiperIndex == 0-->
    <!--                  ? 'animate__animated animate__fadeInLeftBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/left1.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="right"-->
    <!--              :class="-->
    <!--                swiperIndex == 0-->
    <!--                  ? 'animate__animated animate__fadeInRightBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/right1.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="people"-->
    <!--              :class="-->
    <!--                swiperIndex == 0 ? 'animate__animated animate__fadeInUpBig' : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/people1.webp"-->
    <!--            />-->
    <!--            &lt;!&ndash;                        <img @click="iosDownload" v-if="os.isPhone" class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <img @click="androidDownload" v-else class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="footer" @click="toApplication">-->
    <!--            <img src="@/assets/images/mobile1/footer1.png" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-swipe-item>-->
    <!--      <van-swipe-item>-->
    <!--        <div class="content">-->
    <!--          <img class="bg" src="@/assets/images/mobile1/bg.webp" />-->
    <!--          <div class="tip">-->
    <!--            <img class="logo" src="@/assets/images/mobile1/logo.png" />-->
    <!--            <img-->
    <!--              class="tipRight"-->
    <!--              @click="joinGroup"-->
    <!--              src="@/assets/images/mobile1/tipRight.png"-->
    <!--            />-->
    <!--          </div>-->
    <!--          <div class="main">-->
    <!--            <img-->
    <!--              class="left"-->
    <!--              :class="-->
    <!--                swiperIndex == 1-->
    <!--                  ? 'animate__animated animate__fadeInLeftBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/left2.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="right"-->
    <!--              :class="-->
    <!--                swiperIndex == 1-->
    <!--                  ? 'animate__animated animate__fadeInRightBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/right2.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="people"-->
    <!--              :class="-->
    <!--                swiperIndex == 1 ? 'animate__animated animate__fadeInUpBig' : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/people2.webp"-->
    <!--            />-->
    <!--            &lt;!&ndash;                        <img @click="iosDownload" v-if="os.isPhone" class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <img @click="androidDownload" v-else class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="footer" @click="toApplication">-->
    <!--            <img src="@/assets/images/mobile1/footer2.png" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-swipe-item>-->
    <!--      <van-swipe-item>-->
    <!--        <div class="content">-->
    <!--          <img class="bg" src="@/assets/images/mobile1/bg.webp" />-->
    <!--          <div class="tip">-->
    <!--            <img class="logo" src="@/assets/images/mobile1/logo.png" />-->
    <!--            <img-->
    <!--              class="tipRight"-->
    <!--              @click="joinGroup"-->
    <!--              src="@/assets/images/mobile1/tipRight.png"-->
    <!--            />-->
    <!--          </div>-->
    <!--          <div class="main">-->
    <!--            <img-->
    <!--              class="left"-->
    <!--              :class="-->
    <!--                swiperIndex == 2-->
    <!--                  ? 'animate__animated animate__fadeInLeftBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/left3.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="right"-->
    <!--              :class="-->
    <!--                swiperIndex == 2-->
    <!--                  ? 'animate__animated animate__fadeInRightBig'-->
    <!--                  : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/right3.png"-->
    <!--            />-->
    <!--            <img-->
    <!--              class="people"-->
    <!--              :class="-->
    <!--                swiperIndex == 2 ? 'animate__animated animate__fadeInUpBig' : ''-->
    <!--              "-->
    <!--              src="@/assets/images/mobile1/people3.webp"-->
    <!--            />-->
    <!--            &lt;!&ndash;                        <img @click="iosDownload" v-if="os.isPhone" class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <img @click="androidDownload" v-else class="downloadBtn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                             src="@/assets/images/mobile1/downloadBtn.png">&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="footer" @click="toApplication">-->
    <!--            <img src="@/assets/images/mobile1/footer3.png" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-swipe-item>-->
    <!--    </van-swipe>-->

    <!--    <div class="downloadBox">-->
    <!--      <img-->
    <!--        @click="iosDownload"-->
    <!--        v-if="os.isPhone"-->
    <!--        class="downloadBtn"-->
    <!--        src="@/assets/images/mobile1/downloadBtn.png"-->
    <!--      />-->
    <!--      <img-->
    <!--        @click="androidDownload"-->
    <!--        v-else-->
    <!--        class="downloadBtn"-->
    <!--        src="@/assets/images/mobile1/downloadBtn.png"-->
    <!--      />-->
    <!--    </div>-->

    <!-- 提示框 -->
    <van-overlay :show="promptShow" class="overlay">
      <div
        class="overlayContent"
        :class="os.isPhone ? 'iosTutorial' : 'androidTutorial'"
        @click.stop
      >
        <div class="btn" @click="promptShow = false"></div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
import handleReplaceURLParams from "@/utils/handleReplaceURLParams";
import getApkInfo1 from "@/api/getApkInfo";

export default {
  props: ["os", "switchData", "ip"],
  comments: {
    Swipe,
    SwipeItem,
  },
  data() {
    return {
      page_url: "",
      swiperIndex: 0,
      promptShow: false,
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    joinGroup() {
      window.location.href = "https://ptcc.in/sis66";
    },
    //跳转到应用中心
    toApplication() {
      if (
        this.page_url.indexOf("SRNA") == -1 &&
        this.page_url.indexOf("DYFLH") == -1
      ) {
        window.location.href = "https://xerz8s4.com/?dc=szs";
      }
    },
    getUrl() {
      this.page_url = window.location.href;
    },
    iosDownload() {
      this.promptShow = true;
      var params = handleReplaceURLParams()
        ? handleReplaceURLParams()
        : JSON.stringify(this.$route.query) === "{}"
        ? "{}"
        : JSON.stringify(this.$route.query);
      const param = {
        appId: 7,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(params),
          ...this.cutInfo,
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal: "ios",
        packageType: 5,
      };
      var url = this.switchData.link[0];
      let dc = this.$route.query.dc;
      let ch = this.$route.query.ch;
      let pc = this.$route.query.pc;
      let linkUrl = "";
      if (dc) {
        linkUrl = url + "?dc=" + dc;
      } else if (ch) {
        linkUrl = url + "?ch=" + ch;
      } else if (pc) {
        linkUrl = url + "?pc=" + pc;
      } else {
        linkUrl = url;
      }

      // this.$router.push({
      //     name: "h5_ios",
      //     query: Object.assign(this.$route.query, {
      //         linkUrl: window.btoa(linkUrl),
      //     }),
      // });
      setTimeout(() => {
        window.location.href = linkUrl;
      }, 300);
      // window.location.href = linkUrl;
      getApkInfo1(param).then((res) => {
        if (res.data.code === 200) {
          // H5
        }
      });
    },
    androidDownload() {
      this.promptShow = true;
      var params = handleReplaceURLParams()
        ? handleReplaceURLParams()
        : JSON.stringify(this.$route.query) === "{}"
        ? "{}"
        : JSON.stringify(this.$route.query);
      const param = {
        appId: 7,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(params),
          ...this.cutInfo,
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal: "android",
      };
      var url = this.switchData.link[0];
      setTimeout(() => {
        window.location.href = url;
      }, 100);

      // this.$router.push({
      //     path: "/tutorial_android",
      //     query: Object.assign(this.$route.query, {
      //         linkUrl: window.btoa(url),
      //     }),
      // });

      getApkInfo1(param).then((res) => {
        if (res.data.code === 200) {
          // H5
        }
      });
    },
    swipeChange(index) {
      this.swiperIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  //overflow: auto;
  // overflow: hidden;
  // background: #262626;

  .content {
    width: 100vw;
    //height: 100vh;
    //background: url("../../../assets/images/mobile1/bg.png") no-repeat;
    //background-size: 100% 100%;

    //.bg {
    //    width: 100%;
    //    position: absolute;
    //    //height: 100vh;
    //    //background-color: red;
    //}

    .topBar {
      padding: 0.3rem 0.5rem 0 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);

      .logo {
        height: 1rem;
      }

      .tipRight {
        height: 0.9rem;
      }
    }

    //.tip{
    //    position: absolute;
    //    top: 3rem;
    //    width: 100%;
    //    display: flex;
    //    justify-content: center;
    //
    //    img{
    //        height: 2rem;
    //    }
    //}

    .swipe {
      //position: absolute;
      //top: 5.5rem;
      width: 100vw;
      height: 100vh;

      .bg {
        width: 100%;
      }

      ::v-deep .van-swipe__indicators {
        //position: absolute;
        //right: 2%;
        //top: 5.5%;
        //left: auto;
        //bottom: -10%;
      }

      ::v-deep .van-swipe__indicator {
        width: 0.2rem;
        height: 0.2rem;
        background: rgba(255, 255, 255, 0.68);
      }

      ::v-deep .van-swipe__indicator--active {
        background: rgb(255, 255, 255);
        width: 0.7rem;
        border-radius: 90px;
      }

      .contentSwipe {
        //background-image: url("../../../assets/images/mobile1/bg.webp");
        //background-size: 100% 100%;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 2rem;
      }

      .contentSwipe1 {
        //display: flex;
        //justify-content: center;
        //margin-bottom: 0.7rem;
        //padding-top: 0.3rem;

        .tip {
          //position: absolute;
          //top: 3rem;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;

          img {
            height: 2rem;
          }
        }

        .people {
          margin-top: 12px;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        img {
          height: 9.5rem;
        }
      }

      .contentSwipe2 {
        //display: flex;
        //justify-content: center;
        //margin-bottom: 0.7rem;
        //padding-top: 0.3rem;

        .tip {
          //position: absolute;
          //top: 3rem;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;

          img {
            height: 2rem;
          }
        }

        .people {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        img {
          height: 9.5rem;
        }
      }

      .contentSwipe3 {
        //display: flex;
        //justify-content: center;
        //margin-bottom: 0.7rem;
        //padding-top: 0.3rem;

        .tip {
          //position: absolute;
          //top: 3rem;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;

          img {
            height: 2rem;
          }
        }

        .people {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        img {
          height: 9.5rem;
        }
      }
    }

    .downloadBox {
      width: 100%;
      position: fixed;
      bottom: 0.7rem;
      left: 0;
      right: 0;
      //position: absolute;
      //top: 15.9rem;
      display: flex;
      justify-content: center;
      margin-bottom: 0.7rem;

      .moreApps {
        height: 1rem;
        z-index: 1;
      }

      .downloadBtn {
        height: 1rem;
        //bottom: 5rem;
        z-index: 1;
        // margin-left: 44px;
      }
    }
    .tutorialTip {
      position: fixed;
      bottom: 0.7rem;
      left: 50%;
      transform: translateX(-50%);
      // width: 109px;
      width: 3rem;
      height: 0.5rem;
      background: url("../../../assets/images/mobile1/tutorialTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  //.downloadBox {
  //  .downloadBtn {
  //    position: absolute;
  //    height: 3rem;
  //    top: 11rem;
  //    //bottom: 5rem;
  //    z-index: 1;
  //  }
  //}

  //.content {
  //  width: 100vw;
  //  //height: 100vh;
  //  //background: url("../../../assets/images/mobile1/bg.png") no-repeat;
  //  //background-size: 100% 100%;
  //  overflow-y: scroll;
  //  overflow-y: hidden;
  //  -webkit-overflow-scrolling: touch;
  //
  //  //.tip {
  //  //  padding: 0.5rem 0.8rem 0 1.2rem;
  //  //  display: flex;
  //  //  justify-content: space-between;
  //  //  align-items: center;
  //  //  position: absolute;
  //  //  top: 0;
  //  //  width: 100%;
  //  //
  //  //  .logo {
  //  //    height: 67px;
  //  //  }
  //  //
  //  //  .tipRight {
  //  //    height: 0.55rem;
  //  //  }
  //  //}
  //
  //  .main {
  //    width: 100%;
  //    //height: 15rem;
  //    //position: absolute;
  //    //bottom:2.7rem;
  //    //display: flex;
  //    //justify-content: center;
  //
  //    .left {
  //      height: 8rem;
  //      position: absolute;
  //      //top: 3.9rem;
  //      left: 0.1rem;
  //      bottom: 6rem;
  //    }
  //
  //    .right {
  //      height: 9rem;
  //      position: absolute;
  //      //top: 2.9rem;
  //      right: 0.6rem;
  //      bottom: 6rem;
  //    }
  //
  //    .people {
  //      position: absolute;
  //      //top: 2.6rem;
  //      bottom: 2rem;
  //      left: -0.5rem;
  //      height: 15rem;
  //      margin-right: 0.55rem;
  //      z-index: 10;
  //    }
  //
  //    .downloadBtn {
  //      position: absolute;
  //      height: 3rem;
  //      //top: 13.5rem;
  //      bottom: 3rem;
  //      z-index: 20;
  //    }
  //  }
  //
  //  .footer {
  //    width: 100%;
  //    height: 2.5rem;
  //    //height: 15vh;
  //    position: absolute;
  //    bottom: 0rem;
  //    display: flex;
  //    justify-content: center;
  //    z-index: 20;
  //
  //    img {
  //      height: 0.872rem;
  //    }
  //  }
  //}

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    .overlayContent {
      // background: url("../../../assets/images/mobile1/overlayBg.png") no-repeat;
      // background-size: 100% 100%;
      width: 7.92rem;
      height: 11.76rem;
      display: flex;
      justify-content: center;
      position: relative;
      .btn {
        width: 3.44rem;
        height: 0.88rem;
        // background: #000;
        position: absolute;
        bottom: 0.693rem;
        left: 50%;
        transform: translate(-50%);
      }

      .overlayContent {
        margin-top: 0.3rem;
        width: 7rem;
        height: 5rem;
      }

      .confirmBox {
        display: flex;
        justify-content: center;
        margin-top: 0.6rem;

        .confirmBtn {
          width: 5.08rem;
          height: 1rem;
        }
      }
    }
    .iosTutorial {
      background: url("../../../assets/images/mobile1/iosTutorial.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
    .androidTutorial {
      background: url("../../../assets/images/mobile1/androidTutorial.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
  }
}
//
////代表机型 iPhone 6/7/8
//@media screen and (max-width: 375px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.7rem;
//                    height: 13rem;
//                }
//
//                .downloadBtn {
//                    height: 2.7rem;
//                    top: 11rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 Galaxy s8
//@media screen and (max-width: 360px) and (min-height: 740px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3.5rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.3rem;
//                    height: 15rem;
//                    top: 3rem;
//                }
//
//                .downloadBtn {
//                    height: 2.7rem;
//                    top: 13rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 iPhone 14
//@media screen and (min-width: 390px) and (max-width: 390px) and (max-height: 753px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.35rem;
//                    height: 15rem;
//                    top: 2rem;
//                }
//
//                .downloadBtn {
//                    height: 2.8rem;
//                    top: 12rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 iPhone x
//@media screen and (max-width: 375px) and (min-height: 812px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: -0.1rem;
//                    height: 17rem;
//                    top: 2rem;
//                }
//
//                .downloadBtn {
//                    height: 2.9rem;
//                    top: 13rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 iPhone 6/7/8 plus
//@media screen and (min-width: 414px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.7rem;
//                    height: 13rem;
//                }
//
//                .downloadBtn {
//                    height: 2.7rem;
//                    top: 11rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 iPhone XR
//@media screen and (max-width: 414px) and (min-height: 896px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.1rem;
//                    height: 16rem;
//                    top: 3rem;
//                }
//
//                .downloadBtn {
//                    height: 2.9rem;
//                    top: 13.5rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 Galaxy A51
//@media screen and (max-width: 414px) and (min-height: 914px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.1rem;
//                    height: 16rem;
//                    top: 3.4rem;
//                }
//
//                .downloadBtn {
//                    height: 2.8rem;
//                    top: 14rem;
//                }
//            }
//        }
//    }
//}
//
////代表机型 Nexus 5x
//@media screen and (min-width: 412px) and (max-height: 732px) {
//    .wrapper {
//        .content {
//            .main {
//
//                .left {
//                    height: 8rem;
//                    position: absolute;
//                    top: 3rem;
//                    left: 0.1rem;
//                }
//
//                .right {
//                    height: 9rem;
//                    position: absolute;
//                    top: 2.3rem;
//                    right: 0.6rem;
//                }
//
//                .people {
//                    left: 0.9rem;
//                    height: 12rem;
//                    top: 2rem;
//                }
//
//                .downloadBtn {
//                    height: 2.6rem;
//                    top: 10rem;
//                }
//            }
//        }
//    }
//}
</style>
