<template>
  <div class="container">
    <div id="box" class="box">
      <div class="box-row" @click="showPage = 1">
        <span class="span1">充值问题</span>
        <img src="./img/right.svg" />
      </div>
      <div class="box-row" @click="showPage = 2">
        <span class="span3">提现问题</span>
        <img src="./img/right.svg" />
      </div>
      <div></div>
    </div>
    <v-touch
      v-show="showPage === 1"
      id="page1"
      :swipe-options="{ direction: 'horizontal' }"
      class="page page1"
      @swipeleft="showPage = null"
      @swiperight="showPage = null"
    >
      <h3 class="p1">1.如何充值金币</h3>
      <p>1）直播页面打开礼物面板，可以快速充值</p>
      <p class="p2">2）在“我的-更多-钱包”页面中进行充值</p>
      <h3>2.为什么提示“充值失败”/“充值遇到问题”？</h3>
      <p>1）请检查付款时网络链接是否正常</p>
      <p class="p2">2）请检查付款账号余额是否大于购买的金币数量</p>
      <h3>3.付款成功，但金币没到账？</h3>
      <p>
        1）由于网络原因可能导致金币到账延迟，滞后90分钟内属于正常现象，请耐心等候
      </p>
      <p>
        2）如果付款成功且超过48小时金币仍未到账，请在【消息】-【在线客服】进行反馈，会有工作人员在工作时间帮你核查，反馈时请提供以下界面截图，缺一不可
      </p>
      <p>
        （1）、（支付成功后的订单详情页）截图，需包含消费时间、金额、收款对象
      </p>
      <p>
        （2）、个人主页（我）-右上角（三）-（钱包）-（充值）-（充值记录）页截图
      </p>
      <h3>4.每日提现限额</h3>
      <p>提现每笔最小100元，提现每天最多10000元</p>
    </v-touch>
    <v-touch
      v-show="showPage === 2"
      id="page2"
      :swipe-options="{ direction: 'horizontal' }"
      class="page page2"
      @swipeleft="showPage = null"
      @swiperight="showPage = null"
    >
      <h3 class="p1">1.如何提现？</h3>
      <p>
        前往提现页面：“个人主页-右上角-钱包”，点击我的收益开始提现，提现前需在音色平台的账户中绑定手机号，否则无法进入提现操作
      </p>
      <p>1）支持支付宝 / 银行卡</p>
      <p>
        2）按照要求绑定支付宝账号 /
        绑定银行卡以及验证信息，请确保提供的信息准确无误，以免绑定失败
      </p>
      <p class="p2">3）绑定信息验证成功后，即可选择提现金额，并完成提现</p>
      <h3>2.显示“提现失败”</h3>
      <p>
        1）请检查要提现金额是否大于可提现金额，且是否符合提现的最低&最高限额
      </p>
      <p>2）银行卡提现时，需确认绑定的银行卡可正常使用</p>
      <p class="p2">
        3）支付宝提现失败时，需确认当前绑定的支付宝账号是否正确以及支付宝账号是否可用
      </p>
      <h3>3.显示“提现成功”但没到账</h3>
      <p>
        1）请检查要提现金额是否大于可提现金额，且是否符合提现的最低&最高限额
      </p>
      <p class="p2">
        2）高峰期提现人数多会导致网络拥堵，到账之后72小时内属于正常现象，请耐心等候，如72小时后仍未到账，请在【消息】-【在线客服】中将问题反馈给我们，反馈时请提供APP内的提现记录
      </p>
      <h3>4.提现有手续费吗？</h3>
      <p>金币提现手续费为15%</p>
    </v-touch>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPage: null
    };
  }
};
</script>

<style>
@import url("./style.css");
</style>
