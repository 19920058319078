/*
 * @Author: 王枫叶
 * @Date: 2020-07-20 20:48:00
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-10-05 17:23:15
 */
import request from "@/utils/request";

export default data =>
  request({
    method: "post",
    url: "/qaz/wsx/edc/v1yh036f",
    data
  });
