<template>
  <div class="wrapper animated">
    <div class="close" @click="closeTutorial">关闭</div>
    <div class="closeA"></div>
    <div class="pf-cont">
      <div class="pf-cont-item" style="padding-top:15px">
        <div class="i logo">
          <div class="top_content">
            <img
              src="@/assets/images/mobile1/tutorial_icon.png"
              width="26%"
              alt
            />
            <p>色中色</p>
          </div>
          <div class="i-btn-panle" style="margin-bottom:10px">
            <div
              role="button"
              aria-label="色中色，华为版本下载教程"
              aria-labelledby="色中色，华为版本下载教程"
              class="btn"
              style="width: 70vw;height:40px;border-radius: 19px; margin-bottom:25px;font-size:16px;font-weight: 700;display: flex;justify-content: center;align-items: center;"
              @click="toRoute('hw')"
            >
              华为 / 荣耀手机安装教程
            </div>
            <div
              role="button"
              aria-label="色中色，OPPO版本下载教程"
              aria-labelledby="色中色，OPPO版本下载教程"
              class="btn"
              style="width: 70vw;height:40px;border-radius: 19px; margin-bottom:25px;font-size:16px;font-weight: 700;display: flex;justify-content: center;align-items: center;"
              @click="toRoute('op')"
            >
              OPPO手机安装教程
            </div>
            <div
              role="button"
              aria-label="色中色，小米版本下载教程"
              aria-labelledby="色中色，小米版本下载教程"
              class="btn"
              style="width: 70vw;height:40px;border-radius: 19px; margin-bottom:25px;font-size:16px;font-weight: 700;display: flex;justify-content: center;align-items: center;"
              @click="toRoute('xm')"
            >
              小米手机安装教程
            </div>
            <div
              role="button"
              aria-label="色中色，魅族版本下载教程"
              aria-labelledby="色中色，魅族版本下载教程"
              class="btn"
              style="width: 70vw;height:40px;border-radius: 19px; margin-bottom:25px;font-size:16px;font-weight: 700;display: flex;justify-content: center;align-items: center;"
              @click="toRoute('mz')"
            >
              魅族手机安装教程
            </div>
            <div
              role="button"
              aria-label="色中色，VIVO版本下载教程"
              aria-labelledby="色中色，VIVO版本下载教程"
              class="btn"
              style="width: 70vw;height:40px;border-radius: 19px; margin-bottom:25px;font-size:16px;font-weight: 700;display: flex;justify-content: center;align-items: center;"
              @click="toRoute('vo')"
            >
              VIVO手机安装教程
            </div>
          </div>
          <div class="bottom_content">
            <p>如有其他问题，请加入官方交流群咨询</p>
            <a href="https://ptcc.in/sis66">
              <div>点击加入</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toRoute(key) {
      const q = { ...this.$route.query, ...{ key } };
      this.$router.push({ path: "/solution_details", query: q });
    },
    //返回按钮（关闭）
    closeTutorial() {
      this.$router.push({ path: "/", query: this.$route.query });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  // background-image: url("../../../../assets/images/mobile/yindao_bg.jpg");
  .close {
    text-align: center;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    color: #000;
    z-index: 22;
  }

  .closeA {
    position: fixed;
    top: 10px;
    right: 0;
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.5rem;
    opacity: 0.4;
    background: rgb(54, 51, 51);
    color: rgb(54, 51, 51);
    z-index: 21;
  }

  .pf-cont {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 20;
    overflow: auto;
    padding-top: 0.3rem;

    .pf-cont-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .i {
        flex: 1;
      }

      .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top_content {
          text-align: center;

          p {
            font-size: 30px;
            font-weight: 500;
            margin-top: 36px;
          }
        }

        .bottom_content {
          text-align: center;

          p {
            font-size: 30px;
            font-weight: 400;
            margin-top: 36px;
          }

          .div {
            width: 4rem;
            height: 1.2rem;
            border-radius: 0.5rem;
            margin-top: 0.5rem;
            font-size: 30px;
            font-weight: 400;
          }
        }

        .i-btn-panle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 15px;
          margin-top: 0.5rem;

          .btn {
            background: #efefef;
          }
        }
      }
    }
  }
}
</style>
