<template>
  <div class="bate-install animated bounceInUp">
    <div class="close" @click="closeTutorial">
      <span class="close_text">关闭</span>
    </div>
    <div class="container">
      <div v-if="switchData.iosTF">
        <div class="step1">
          <div class="text1" style="font-size: 14px">
            <span>1.</span>
            <span>先安装</span>
            <span>Testflight</span>
            <span>, </span>
            <span> 再回到本页</span>
            <span>，安装</span>
            <span>稳定包</span>
          </div>
          <div class="app_tf">
            <div class="tf_name">
              <img src="@/assets/images/mobile1/mb_tf/tf_icon.png" alt />
              <h3 style="font-size: 16px">Testflight</h3>
            </div>
            <div class="tf_btn" @click="uploadTestFilght">
              <span style="font-size: 14px">安装苹果Testflight</span>
            </div>
            <div
              class="el_btn"
              style="font-size: 14px"
              type="text"
              @click="dialogVisible = true"
            >
              Testflight不可用?
            </div>
          </div>
        </div>
        <div class="lines"></div>
        <div class="step2">
          <!-- <p
          style="text-align:left;font-size:0.5rem;padding:0.2rem 0;font-widget:600"
        >
          步骤二：
        </p> -->
          <div class="text2" style="font-size: 14px">
            <span>2. </span>
            <span> 回到本页，安装下面的</span>
            <span>稳定包</span>
          </div>
          <div class="app_wending" v-if="flag === false">
            <div class="wd_name">
              <img src="@/assets/images/mobile1/mb_tf/app_icon.png" alt />
              <h3 style="font-size: 16px">色中色</h3>
            </div>
            <div class="wd_btn" @touchend="touchend('string')">
              <span style="font-size: 14px">安装官方稳定版</span>
            </div>
          </div>
          <div v-if="flag" class="more_btn">
            <div class="app_wending" v-for="(item, i) in apkList" :key="i">
              <div class="wd_name">
                <img
                  style="border-radius: 20%"
                  :src="item.appIcon ? item.appIcon : url_img"
                  alt
                />
                <h3 style="font-size: 16px">
                  {{ item.appName ? item.appName : "色中色" }}
                </h3>
              </div>
              <div class="wd_btn" @click="dialogVisibleOne = true">
                <span style="font-size: 14px">安装官方稳定版</span>
              </div>
              <!-- IOS下载提示弹窗 -->
              <div
                v-if="dialogVisibleOne"
                @click="handleClose"
                class="dialog_cust"
              >
                <div class="dialog_warp">
                  <div
                    style="font-size: 16px; line-height: 20px; font-weight: 400"
                  >
                    <p>
                      若出现testflight无法进appstore
                      connect或是无法打开的情况，有如下解决方法：
                    </p>
                    <p>方法一：</p>
                    <p>1.先在AppStore下载腾讯加速器</p>
                    <p>2.搜索“steam国际服”点击加速</p>
                    <p>3.重新打开testflight</p>
                    <p>方法二：</p>
                    <p>1.WIFI网络的dns改为手动并设置为8.8.4.4或者8.8.8.8</p>
                    <p>2.切换网络WIFI或者4G</p>
                    <p>3.连接他人WIFI</p>
                    <p style="margin-top: 20px">
                      备注：一开始安装显示APP可能为其它APP，下载安装成功后，即会自动显示色中色APP，请安心下载。
                    </p>
                  </div>
                  <span slot="footer" class="dialog-btn">
                    <div
                      style="
                    border: none;
                    font-size: 16px;
                    font-weight: 700;
                    color: #2b75e1;
                  "
                      @click="touchend('json', i)"
                    >
                      确定下载
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style="color: #999; margin-top: 20px">
          若提示测试人数已满或链接失效,请选择其他稳定包下载
        </p>
        <div class="lines"></div>
      </div>
      <div
        class="step2"
        v-if="switchData.iosDown || switchData.iosMdm || switchData.iosH5"
      >
        <p
          style="text-align:left;font-size:0.5rem;padding:0.2rem 0;font-widget:600"
        >
          {{ this.switchData.iosTF ? "备用下载：" : "请选择下载方式" }}
        </p>
        <div>
          <div
            class="enterprise_btn"
            @click="enterprise_btn"
            v-if="switchData.iosDown"
          >
            <span style="font-size: 14px">企业签下载</span>
          </div>
          <div
            class="enterprise_btn"
            @click="iosMdm_btn"
            v-if="switchData.iosMdm"
          >
            <span style="font-size: 14px">超级签下载</span>
          </div>
          <div
            class="enterprise_btn"
            @click="iosH5_btn"
            v-if="switchData.iosH5"
          >
            <span style="font-size: 14px">轻量版下载</span>
          </div>
        </div>
      </div>
      <div>
        <!-- <p style="color: #999; margin-top: 20px">
          若提示测试人数已满或链接失效,请选择其他稳定包下载
        </p> -->
        <p class="tips">
          注意：受苹果政策影响，实际图标及名称可能与官方不同，无需担心，放心安装。
        </p>
      </div>
      <div class="join_group">
        <img src="@/assets/images/mobile1/mb_tf/potato_icon.png" alt="" />
        <div class="detail">
          <p>如有问题，可加群了解</p>
          <p>老司机火爆交流群（无需翻墙）</p>
          <p>
            <a href="https://ptcc.in/sis66">
              <span style="font-size: 16px">点击加入</span>
              <img src="@/assets/images/mobile1/mb_tf/join.png"
            /></a>
          </p>
        </div>
      </div>
    </div>
    <div v-if="dialogVisible" @click="handleClose" class="dialog_cust">
      <div class="dialog_warp">
        <span class="dialog-title">
          <span style="font-size: 16px; font-weight: 500">解决方法</span>
        </span>

        <span style="font-size: 14px; margin-bottom: 16px; line-height: 28px">
          如出现testflight无法接入appStore
          connect,或者无法打开appStore的情况，<span
            style="color: rgba(255, 100, 97, 0.9)"
          >
            请先修改dns配置为公共dns的IP</span
          >，如：<span style="color: rgba(255, 100, 97, 0.9)"
            >114.114.114.114, 8.8.4.4,</span
          >或者使用VPN连接国外节点下载。</span
        >
        <span slot="footer" class="dialog-btn">
          <div
            style="border: none; font-size: 14px; font-weight: 500"
            @click="dialogVisible = false"
          >
            朕知道了！
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import getApkInfo from "@/api/getApkInfo";
import handleReplaceURLParams from "@/utils/handleReplaceURLParams";

export default {
  data() {
    return {
      url_img: require("../../../../assets/images/mobile1/mb_tf/app_icon.png"),
      type: "",
      active: true,
      params: "",
      apkList: [],
      urlList: [],
      flag: undefined,
      flag_qiye: undefined,
      url: "",
      dialogVisible: false,
      ip: "",
      dialogVisibleOne: false,
      switchData: this.$route.query.switchData
        ? JSON.parse(window.atob(this.$route.query.switchData))
        : {
            iosDown: false,
            iosH5: false,
            iosTF: false
          },
      dataLink: [],
      iosMdmLink: []
    };
  },
  mounted() {
    /**
     * 判断进来的url地址 替换到需要手动设置的参数 如：dc
     */
    this.params = handleReplaceURLParams()
      ? handleReplaceURLParams()
      : JSON.stringify(this.$route.query) === "{}"
      ? "{}"
      : JSON.stringify(this.$route.query);
    // console.log(this.$route.params, this.$route.params, "this.$route.params");
    if (this.switchData.iosTF) {
      this.getiosBeta("bate");
    }
    if (this.switchData.iosDown) {
      this.getiosBeta("ios");
    }
    if (this.switchData.iosMdm) {
      this.getiosBeta("iosMdm");
    }
    if (this.switchData.iosH5) {
      this.getiosBeta("iosH5");
    }
  },
  methods: {
    //tf安装
    uploadTestFilght() {
      window.location.href =
        "https://apps.apple.com/us/app/testflight/id899247664";
    },
    //关闭页面
    closeTutorial() {
      this.$router.push({ path: "/", query: this.$route.query });
    },
    //商店包页面请求
    getiosBeta(type) {
      let terminal;
      let packageType;
      if (type === "bate") {
        terminal = "ios";
        packageType = 3;
      } else if (type === "ios") {
        terminal = "ios";
        packageType = 1;
      } else if (type == "iosMdm") {
        terminal = "ios";
        packageType = 4;
      } else if (type == "iosH5") {
        terminal = "ios";
        packageType = 5;
      } else {
        terminal = "android";
      }
      const param = {
        // eslint-disable-next-line no-undef
        appId: 7,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(this.params),
          ...this.cutInfo
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal,
        packageType
      };
      getApkInfo(param).then(res => {
        if (res.data.code === 200) {
          let type = res.data.data.packageType;
          // 企业包
          if (type == 1) {
            this.dataLink = Object.assign(this.dataLink, res.data.data.link);
          }
          // 超级签
          if (type == 4) {
            this.iosMdmLink = Object.assign(
              this.iosMdmLink,
              res.data.data.link
            );
          }
          // H5
          if (type == 5) {
            this.dataLink = Object.assign(this.dataLink, res.data.data.link);
          }
          //tf
          if (type == 3) {
            if (res.data.data.tf && res.data.data.tf.length > 0) {
              this.flag = true;
              this.apkList = res.data.data.tf;
              this.urlList = res.data.data.tf
                .map(item => {
                  let arr = [];
                  arr.push(item.url);
                  return arr;
                })
                .sort(this.randomsort);
            } else {
              this.flag = false;
            }
          }
        }
      });
    },
    //第二步的 按钮
    touchend(type, i) {
      if (type === "str") {
        window.location.href = "";
      } else {
        if (this.urlList) {
          window.location.href = this.urlList[i];
        } else {
          this.$message.error("链接地址不存在！");
        }
      }
    },
    iosMdm_btn() {
      if (this.iosMdmLink.length == 0) return;
      window.location.href = this.iosMdmLink[0];
    },
    iosH5_btn() {
      if (this.dataLink.length == 0) return;
      var url = this.dataLink[0];
      var code = JSON.parse(this.params).dc;
      if (code) {
        url = this.dataLink[0] + "?dc=" + code;
      }
      this.$router.push({
        name: "h5_ios",
        query: Object.assign(this.$route.query, {
          linkUrl: window.btoa(url)
        })
      });
    },
    enterprise_btn() {
      if (this.dataLink.length == 0) return;
      window.location.href = this.dataLink[0];
      this.$router.push({
        name: "iosEnterprise",
        params: { link: this.dataLink }
      });
    },
    //生成随机数
    randomsort() {
      return Math.random() > 0.5 ? -1 : 1;
      //用Math.random()函数生成0~1之间的随机数与0.5比较，返回-1或1
    },
    handleClose() {
      this.dialogVisible === false;
    }
  }
};
</script>

<style lang="scss">
.bate-install {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 88;
  top: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;

  .container {
    padding: 0.4rem 0.5rem 0.5rem 0.5rem;
    text-align: center;
    position: relative;

    .step1 {
      .text1 {
        text-align: left;
        font-weight: 500;

        span:nth-child(1),
        span:nth-child(5) {
          color: #c10000;
        }

        span:nth-child(3),
        span:nth-child(7) {
          color: #9db2ff;
        }
      }

      .app_tf {
        .tf_name {
          margin: 0.7rem 0 0.3rem 0;

          img {
            width: 20vw;
          }

          h3 {
            margin-top: 0.16rem;
          }
        }

        .tf_btn {
          width: 56vw;
          background-image: linear-gradient(to bottom, #47d2f8, #3084ee);
          margin: 0 auto;
          border-radius: 40px;
          padding: 0.26rem 0;
          margin-bottom: 16px;

          span {
            color: #fff;
          }
        }

        .el_btn {
          color: rgba(48, 132, 238, 0.8);
          margin-top: 1px;
          border-bottom: 3px solid rgba(48, 132, 238, 0.8);
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
        }
      }
    }

    .step2 {
      // margin-top: 1rem;
      .text2 {
        text-align: left;
        font-weight: 500;

        span:nth-child(1) {
          color: #c10000;
        }

        span:nth-child(3) {
          color: #2b75e1;
        }
      }

      .app_wending {
        .wd_name {
          margin: 0.7rem 0 0.3rem 0;

          img {
            width: 21.4vw;
          }

          h3 {
            margin-top: 0.16rem;
          }
        }

        .wd_btn {
          width: 40vw;
          background-image: linear-gradient(to bottom, #47d2f8, #3084ee);
          margin: 0 auto;
          border-radius: 40px;
          padding: 0.26rem 0;

          span {
            color: #fff;
          }
        }
      }

      .more_btn {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }

    .tips {
      color: #ff8800;
      text-align: left;
      margin: 1rem 0 0.6rem 0;
    }

    .join_group {
      text-align: center;
      display: flex;
      justify-content: center;

      img {
        width: 14%;
        height: 10%;
        margin-right: 35px;
      }

      .detail {
        text-align: left;

        p:nth-child(2) {
          margin-top: 10px;
          margin-bottom: 15px;
        }

        p:nth-child(3) {
          position: relative;

          a {
            color: #3387fc;

            img {
              width: 10%;
              position: relative;
              top: 6px;
              left: 2px;
            }
          }
        }
      }
    }

    .btn_tf {
      position: absolute;
      top: 4.8rem;
      right: 0.65rem;
    }

    .btn_app {
      position: absolute;
      top: 9.95rem;
      right: 0.65rem;
    }

    .app_icon {
      position: absolute;
      top: 10rem;
      left: 0.65rem;
    }
  }

  .close {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.5rem;
    opacity: 0.3;
    background: rgb(20, 20, 20);
    text-align: center;
    z-index: 999;

    .close_text {
      color: rgb(250, 250, 250);
    }
  }
}

.enterprise_btn {
  color: #fff;
  width: 60vw;
  background-image: linear-gradient(to bottom, #47d2f8, #3084ee);
  margin: 0.2rem auto 0;
  border-radius: 0.4rem;
  padding: 0.26rem 0;
}

.title_top {
  margin-top: 0.2rem;
  font-size: 0.7rem;

  span {
    color: #f65c2c;
  }
}

.line {
  width: 100%;
  height: 0.1rem;
  margin: 0.3rem;
  background: #e9e9e9;
}

.lines {
  width: 100%;
  height: 0.1rem;
  margin: 0.4rem 0;
  background-image: linear-gradient(
    90deg,
    rgba(211, 2, 2, 0) 0%,
    #dcdfe6 50%,
    rgba(211, 2, 2, 0) 99%
  );
}
</style>
<style lang="scss">
.el-title {
  border-radius: 20px;
}

.el-icon-close:before {
  content: none;
}

.dialog_cust {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);

  .dialog_warp {
    width: 80%;
    background: white;
    margin: 25vh auto 0;
    border-radius: 15px;
    padding: 40px 6px;

    .dialog-title {
      padding-top: 20px;
    }

    .dialog-btn {
      color: #fff;
      display: inline-block;
      margin: 20px 0 0 0;
      padding: 10px 20px;
      background-color: #409eff;
      border-color: #409eff;
    }
  }
}
</style>
