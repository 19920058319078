<!--
 * @Author: 王枫叶
 * @Date: 2020-09-16 21:01:20
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-10-06 21:27:42
-->
<template>
  <div class="app">
    <div class="app_top">
      <div class="close" @click="closeTutorial">
        <span class="close_text">关闭</span>
      </div>
      <div class="content">
        <p class="tip">
          我们产品不涉及诈骗，请放心下载使用，如果打开提示反诈中心页面，请卸载反诈中心APP再打开使用
        </p>
        <img src="@/assets/images/bg.png" alt="" />
        <div style="height:3rem"></div>
      </div>
    </div>
    <div class="btn_warp">
      <div class="click_btn" @click="click_btn">
        下载IOS轻量版
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: [""],
  data() {
    return {
      // url: "https://gest.001424.com",
      // api1: "/app/jf.mobileconfig",
      // api2: "/app/1.mobileprovision",
    };
  },
  created() {
    // setTimeout(() => {
    //   window.location.href = this.url + this.api2;
    // }, 2000);
    // window.location.href = this.url + this.api1;
  },
  components: {},
  computed: {},
  beforeMount() {},
  mounted() {},
  methods: {
    closeTutorial() {
      this.$router.push({ path: "/", query: this.$route.query });
    },
    click_btn() {
      let linkUrl = window.atob(this.$route.query.linkUrl);
      window.location.href = linkUrl;
    }
  },
  watch: {}
};
</script>
<style lang="scss">
.app {
  background: rgb(33, 39, 55);
}
.content {
  img {
    width: 100%;
  }
}
.btn_warp {
  background-color: rgba(68, 61, 74, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999999;
  text-align: center;
  height: 2.5rem;
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
}
.click_btn {
  width: 40vh;
  height: 6vh;
  margin: 0 auto;
  display: block;
  line-height: 5.5vh;
  background: #ffffff;
  border-radius: 5vh;
  font-weight: 700;
  font-size: 0.48rem;
  padding: 0.5vh 0.5vh 0.5vh 0.5vh;
}
.close {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.5rem;
  // opacity: 0.3;
  background: #ef10f3;
  text-align: center;
  z-index: 9999;
  .close_text {
    color: rgb(250, 250, 250);
  }
}
.tip {
  color: red;
  font-size: 0.36rem;
  padding: 1.2rem 0.5rem 0;
  line-height: 0.5rem;
}
</style>
