var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('header',{staticClass:"top"},[_c('img',{staticClass:"logo-img",attrs:{"id":"logo","src":require("@/assets/images/pc/logo.png"),"alt":""}}),_c('div',{staticClass:"top-right"},[_c('img',{staticClass:"downloadBtn",attrs:{"src":require("@/assets/images/pc/downloadBtn.png")},on:{"click":_vm.download}})])]),_c('div',{staticClass:"qrBtn"},[_c('div',{staticClass:"qr",class:_vm.downShow ? 'animate__animated animate__pulse' : ''},[_c('img',{attrs:{"src":_vm.qrImg,"alt":""}})]),_c('div',{staticClass:"btnBox"},[_c('img',{staticClass:"iosBtn",attrs:{"src":require("@/assets/images/pc/iosBtn.png")},on:{"click":_vm.download}}),_c('img',{staticClass:"androidBtn",attrs:{"src":require("@/assets/images/pc/androidBtn.png")},on:{"click":_vm.download}}),_c('div',{staticClass:"ucTip"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperIndex == 0),expression:"swiperIndex == 0"}],staticClass:"tip",class:_vm.swiperIndex == 0
        ? _vm.roll == 'up'
          ? 'animate__animated animate__fadeInUp'
          : 'animate__animated animate__fadeInDown'
        : ''},[_c('img',{staticClass:"tip_1",attrs:{"src":require("@/assets/images/pc/tip_1.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperIndex == 1),expression:"swiperIndex == 1"}],staticClass:"tip",class:_vm.swiperIndex == 1
        ? _vm.roll == 'up'
          ? 'animate__animated animate__fadeInUp'
          : 'animate__animated animate__fadeInDown'
        : ''},[_c('img',{staticClass:"tip_2",attrs:{"src":require("@/assets/images/pc/tip_2.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperIndex == 2),expression:"swiperIndex == 2"}],staticClass:"tip",class:_vm.swiperIndex == 2
        ? _vm.roll == 'up'
          ? 'animate__animated animate__fadeInUp'
          : 'animate__animated animate__fadeInDown'
        : ''},[_c('img',{staticClass:"tip_3",attrs:{"src":require("@/assets/images/pc/tip_3.png")}})]),_c('van-swipe',{ref:"swipe",staticClass:"swipe",attrs:{"duration":"1000","autoplay":"3000","show-indicators":false,"vertical":"","loop":""},on:{"change":_vm.swipeChange}},[_c('van-swipe-item',[_c('div',{staticClass:"contentSwipe1"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/images/pc/people_1.webp")}})])])]),_c('van-swipe-item',[_c('div',{staticClass:"contentSwipe2"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/images/pc/people_2.webp")}})])])]),_c('van-swipe-item',[_c('div',{staticClass:"contentSwipe3"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/images/pc/people_3.webp")}})])])])],1),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('span',[_vm._v("温馨提示：应用内包含成人内容，下载前请确认您已满18岁，本平台主要收益为广告赞助，且保证APP安全无毒，如遇报毒提醒请忽略！")])])}]

export { render, staticRenderFns }