<!--
 * @Author: 王枫叶
 * @Date: 2020-10-13 17:42:40
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-11-13 17:16:04
-->
<template>
  <div class="img-wra">
    <div class="close" @click="closeTutorial">关闭</div>
    <div class="closeA"></div>
    <img :src="src[key]" width="100%" class="" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      key: "hw",
      src: {
        hw: require("../../../../../assets/images/mobile1/hw.png"),
        xm: require("../../../../../assets/images/mobile1/xmt.jpg"),
        mz: require("../../../../../assets/images/mobile1/mzt.jpg"),
        op: require("../../../../../assets/images/mobile1/opt.jpg"),
        vo: require("../../../../../assets/images/mobile1/vot.jpg")
      }
    };
  },
  created() {
    this.key = this.$route.query.key;
  },
  methods: {
    closeTutorial() {
      delete this.$route.query.key;
      this.$router.push({ path: "/", query: this.$route.query });
    }
  }
};
</script>

<style lang="scss" scoped>
.img-wra {
  width: 100vw;
  height: 100vh;
  // background: #000;
  position: relative;
  padding: 10px;
  .close {
    text-align: center;
    position: fixed;
    top: 10px;
    right: 0;
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    color: #fff;
    z-index: 22;
  }
  .closeA {
    position: fixed;
    top: 10px;
    right: 0;
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.5rem;
    opacity: 0.4;
    background: rgb(55, 58, 59);
    color: rgb(55, 58, 59);
    z-index: 21;
  }
}
</style>
