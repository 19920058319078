<!--
 * @Author: 王枫叶
 * @Date: 2020-10-14 17:36:34
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-11-17 20:23:02
-->
<!--  -->
<template>
  <div class="promotinn_event">
    <div class="top">
      <img :src="top" />
    </div>
    <div class="content">
      <img :src="join" />
      <img :src="invite_btn" @click="toPromote" />
      <img :src="method" />
      <img :src="reward" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  data() {
    return {
      top: require("./image/top.jpg"),
      join: require("./image/join.png"),
      invite_btn: require("./image/invite_btn.png"),
      method: require("./image/method.png"),
      reward: require("./image/reward.png")
    };
  },
  methods: {
    toPromote() {
      // eslint-disable-next-line no-undef
      CallFlutter.postMessage(
        JSON.stringify({
          name: "Jump",
          params: {
            page: "page_promote"
          }
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.promotinn_event {
  width: 100vw;
  box-sizing: border-box;
  background: #1c0648;
  margin: 0 auto;
  img {
    width: 100vw;
  }
  .content {
    text-align: center;
    img {
      width: 94vw;
    }
    img:nth-child(2) {
      margin: 50px 0;
    }
    img:last-child {
      margin-top: 40px;
      margin-bottom: 60px;
    }
  }
}
</style>
