<!--
 * @Author: 王枫叶
 * @Date: 2020-10-14 17:36:34
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-11-17 20:41:37
-->
<!--  -->
<template>
  <div class="rebate">
    <img :src="top" />
    <img :src="flicker" />
    <img :src="description" />
    <img :src="eggs" />
    <img :src="recharge_btn" @click="toPromote" />
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  data() {
    return {
      top: require("./image/top.png"),
      flicker: require("./image/flicker.png"),
      description: require("./image/description.png"),
      eggs: require("./image/eggs.png"),
      recharge_btn: require("./image/recharge_btn.png")
    };
  },
  methods: {
    toPromote() {
      // eslint-disable-next-line no-undef
      CallFlutter.postMessage(
        JSON.stringify({
          name: "Jump",
          params: {
            page: "page_glod_mgr"
          }
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.rebate {
  width: 100vw;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  font-size: 0;
  img {
    width: 100vw;
    padding: 0;
  }
}
</style>
