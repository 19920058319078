<template>
  <div class="app">
    <div class="app_top">
      <div class="close" @click="closeTutorial">
        <span class="close_text">关闭</span>
      </div>
      <h3>色中色APP企业版安装教学手册</h3>
      <hr />
      <div class="download_page_main" v-if="flag_qiye">
        <button class="download_btn" @click="down">安装官方企业版</button>
      </div>
    </div>
    <img src="@/assets/images/mobile1/SIS.png" alt="" class="help" />
  </div>
</template>
<script>
import getApkInfo from "@/api/getApkInfo";
import handleReplaceURLParams from "@/utils/handleReplaceURLParams";
export default {
  data() {
    return {
      dataLink: [],
      params: "",
      flag_qiye: false
    };
  },
  mounted() {
    this.params = handleReplaceURLParams()
      ? handleReplaceURLParams()
      : JSON.stringify(this.$route.query) === "{}"
      ? "{}"
      : JSON.stringify(this.$route.query);
    this.getiosBeta();
  },
  methods: {
    closeTutorial() {
      this.$router.back(-1);
    },
    down() {
      if (
        this.$route.params.link != null &&
        this.$route.params.link.length > 0
      ) {
        let link = this.$route.params.link[0];
        window.location.href = link;
      }

      // 当前页面请求而来
      if (this.dataLink.length > 0) {
        window.location.href = this.dataLink[0];
      }
    },
    //商店包页面请求
    getiosBeta() {
      const param = {
        // eslint-disable-next-line no-undef
        appId: 7,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(this.params),
          ...this.cutInfo
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal: "ios",
        packageType: 1
      };
      getApkInfo(param).then(res => {
        if (res.data.code === 200) {
          let type = res.data.data.packageType;
          // 企业包
          if (type == 1) {
            this.dataLink = Object.assign(this.dataLink, res.data.data.link);
            if (this.dataLink.length > 0) {
              this.flag_qiye = true;
            } else {
              this.flag_qiye = false;
            }
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.app {
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  .app_top {
    width: 100%;
    position: fixed;
    background: #fff;
    top: 0;
  }
  h3 {
    font-size: 0.4rem;
    padding: 0.5rem 0;
  }

  .download_page_main {
    margin: 0.2rem 0 0 0;
    .download_btn {
      width: 60%;
      color: #fff;
      border: none;
      font-size: 0.4rem;
      font-weight: bold;
      background-image: linear-gradient(to bottom, #aa6dde, #6f558a);
      margin: 0 auto;
      border-radius: 0.6rem;
      padding: 0.26rem 0;
    }
  }
  .help {
    width: 100%;
    padding-top: 3.3rem;
  }
}
.close {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.5rem;
  opacity: 0.3;
  background: rgb(20, 20, 20);
  text-align: center;
  z-index: 999;
  .close_text {
    color: rgb(250, 250, 250);
  }
}
</style>
