/*
 * @Author: 王枫叶
 * @Date: 2020-07-20 20:48:00
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-11-17 20:27:59
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/land/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "色中色"
    }
  },
  {
    path: "/iosBeta",
    name: "IosBeta",
    meta: {
      title: "商店包"
    },
    component: () => import("../views/land/components/page/iosBeta.vue")
  },
  {
    path: "/iosEnterprise",
    name: "iosEnterprise",
    meta: {
      title: "色中色"
    },
    component: () => import("../views/land/components/page/iosEnterprise.vue")
  },
  {
    path: "/h5/app/qa",
    name: "qa",
    meta: {
      title: "常见问题"
    },
    component: () => import("../views/H5/qa")
  },

  {
    path: "/h5/app/proxy_rule",
    name: "proxy_rule",
    meta: {
      title: "代理规则"
    },
    component: () => import("../views/H5/proxy_rule")
  },
  {
    path: "/promote",
    name: "promote",
    meta: {
      title: "推广邀请活动"
    },
    component: () => import("../views/H5/promote")
  },
  {
    path: "/rebate",
    name: "rebate",
    meta: {
      title: "全民返利"
    },
    component: () => import("../views/H5/rebate")
  },
  {
    path: "/h5_ios",
    name: "h5_ios",
    meta: {
      title: "ios下载引导页"
    },
    component: () => import("../views/land/components/page/h5_ios.vue")
  },
  {
    path: "/tutorial_android",
    name: "tutorial_android",
    meta: {
      title: "安卓安装教程"
    },
    component: () =>
      import("../views/land/components/page/tutorial_android/index.vue")
  },
  {
    path: "/solution_details",
    name: "solution_details",
    meta: {
      title: "解决方案详情"
    },
    component: () =>
      import(
        "../views/land/components/page/tutorial_android/solution_details.vue"
      )
  },
  {
    path: "/solution",
    name: "solution",
    meta: {
      title: "报毒解决方案"
    },
    component: () =>
      import("../views/land/components/page/tutorial_android/solution.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
