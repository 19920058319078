<template>
  <div class="container">
    <img src="./img/bg.jpg" />
  </div>
</template>
<script>
export default {
  fetch() {},
  data() {
    return {};
  },

  mounted() {},
  head() {}
};
</script>

<style>
@import url("./style.css");
</style>
